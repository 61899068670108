<template>
  <app-timeline>
    <app-timeline-item
      v-for="(history, index) in all_history"
      :key="`req-${index}`"
      :variant="getTimelineItemVariant(history)"
      class="pb-3"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6 class="text-muted">
          {{ history.change_by }}
        </h6>
        <h6 class="text-muted">
          {{ history.ts | formatDate }}
        </h6>
      </div>
      <div v-if="history.action === 'update'">
        <div
          v-for="(change, p_index) in history.diff"
          :key="`${p_index}`"
        >
          <p class="mb-25 font-weight-bold text-capitalize text-warning">
            {{ change[1] === 'object_text' ? 'Requirement' : change[1] }}
          </p>
          <div v-if="change[1] === 'object_text'" class="ml-50">
            <span class="object_text text-muted" v-sanitized-html="change[2][0]" />
            <feather-icon icon="ArrowDownIcon" class="text-success ml-2 my-25" size="16" />
            <span class="object_text" v-sanitized-html="change[2][1]" />
          </div>
          <div v-else class="mb-50 ml-50">
            <span>{{ change[2][0] }}</span>
            <feather-icon icon="ArrowRightIcon" class="text-success mx-50 mb-25" size="16" />
            <span>{{ change[2][1] }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mb-50 ml-50">
          {{ history.diff }}
        </div>
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import { mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'History',
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      all_history: [],
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
    }),
  },
  mounted() {
    this.loadRequirementChangeHistory()
  },
  methods: {
    getTimelineItemVariant({ action }) {
      switch (action) {
      case 'update': return 'warning'
      case 'delete': return 'danger'
      default: return 'success'
      }
    },
    loadRequirementChangeHistory() {
      this.$http
        .get(`/api/v2/requirement/${this.selected_requirement.properties.id}/history`)
        .then(({ data }) => {
          console.log(data)
          this.all_history = data
        })
    },
  },
}
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.object_text ::v-deep p {
  margin: 0;
}
</style>
